"use client";

import type { ReactNode } from "react";
import type { LinkButtonProps } from "@/components/Button/components/LinkButton/LinkButton.component";
import { LinkButton } from "@/components/Button/components/LinkButton/LinkButton.component";
import { useUser } from "@/contexts/user/user.context";
type LoginButtonProps = {
  readonly className?: string;
  readonly children: ReactNode;
  readonly redirectTo?: string;
  readonly variant?: LinkButtonProps["variant"];
};
export function LoginButton({
  className,
  children,
  redirectTo,
  variant = "primary"
}: LoginButtonProps) {
  const {
    urls: {
      createLoginUrl,
      loginAndReturnUrl
    }
  } = useUser();
  return <LinkButton className={className} href={redirectTo !== undefined ? createLoginUrl(redirectTo) : loginAndReturnUrl} openInNewTab={false} variant={variant} data-sentry-element="LinkButton" data-sentry-component="LoginButton" data-sentry-source-file="LoginButton.component.tsx">
      {children}
    </LinkButton>;
}