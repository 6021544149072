import { clsx } from "clsx";
import type { ReactNode } from "react";
import { Link } from "@/components/Link/Link.component";
export type LinkButtonProps = {
  readonly className?: string;
  readonly openInNewTab?: boolean | "determineFromSource";
  readonly href: string;
  readonly children: ReactNode;
  readonly position?: string;
  readonly isDisabled?: boolean;
  readonly variant?: "primary" | "secondary" | "secondary-inverse";
};
export function LinkButton({
  className,
  href,
  openInNewTab,
  children,
  position,
  isDisabled = false,
  variant = "primary"
}: LinkButtonProps) {
  return <Link className={clsx(className, "base-button base-hover-button border", isDisabled && "border-silver bg-silver text-black hover:border-silver hover:bg-silver", variant === "secondary" && !isDisabled && "border border-silver bg-silent text-black hover:bg-smoke", variant === "secondary-inverse" && !isDisabled && "border-white bg-transparent text-white hover:border-primary-hover hover:bg-primary-hover", variant === "primary" && !isDisabled && "border-primary bg-primary text-white hover:border-primary-hover hover:bg-primary-hover")} disabled={isDisabled} href={href} openInNewTab={openInNewTab} position={position} data-sentry-element="Link" data-sentry-component="LinkButton" data-sentry-source-file="LinkButton.component.tsx">
      {children}
    </Link>;
}