"use client";

import { clsx } from "clsx";
import { usePathname } from "next/navigation";
import { LoginButton } from "@/components/Button/components/LoginButton/LoginButton.component";
import { archivePageLink } from "@/components/page-links.constants";
import { Translation } from "@/components/Translation/Translation.component";
import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
import { useUser } from "@/contexts/user/user.context";
import { AccountButton } from "../Account/AccountButton.component";
import { BuyButton } from "../BuyButton/BuyButton.component";
import { MenuItem } from "../MenuItem/MenuItem.component";
import { ToolbarMenuItem } from "./components/ToolbarMenuItem.component";
type ToolbarMenuProps = {
  readonly className?: string;
  readonly theme?: "white" | "primary";
};
export function ToolbarMenu({
  className,
  theme = "primary"
}: ToolbarMenuProps) {
  const pathname = usePathname();
  const isArchivePage = pathname === archivePageLink;
  const {
    isLoggedIn,
    model
  } = useUser();
  const siteConfiguration = useSiteConfiguration();
  const hideLogin = Boolean(siteConfiguration.hideLogin);
  return <ul className={clsx("flex h-full items-center justify-end", className)} data-sentry-component="ToolbarMenu" data-sentry-source-file="ToolbarMenu.component.tsx">
      <ToolbarMenuItem className={clsx("transition-all", theme === "primary" && "font-theme-bold hover:text-primary", theme === "white" && "text-inherit after:absolute after:bottom-0 after:left-1/2 after:block after:h-0.5 after:w-0 after:-translate-x-1/2 after:bg-secondary after:transition-all after:content-[''] hover:text-secondary", isArchivePage && theme === "primary" && "text-primary", isArchivePage && theme === "white" && "font-theme-bold text-white after:!w-full after:bg-white hover:after:bg-secondary")} data-sentry-element="ToolbarMenuItem" data-sentry-source-file="ToolbarMenu.component.tsx">
        <MenuItem className="h-full w-full px-2 text-inherit" position="nav-search" targetUrl={archivePageLink} data-sentry-element="MenuItem" data-sentry-source-file="ToolbarMenu.component.tsx">
          <Translation da="Søg" de="Suchen" en="Search" no="Søk" sv="Sök" data-sentry-element="Translation" data-sentry-source-file="ToolbarMenu.component.tsx" />
        </MenuItem>
      </ToolbarMenuItem>

      {!hideLogin ? <ToolbarMenuItem>
          {isLoggedIn ? <AccountButton /> : <LoginButton className="lg:p-1.25" variant={theme === "primary" ? "secondary" : "secondary-inverse"}>
              <Translation da="Log ind" de="Anmelden" en="Log in" no="Logg inn" sv="Logga in" />
            </LoginButton>}
        </ToolbarMenuItem> : null}

      {model.access.$class !== "ip" && model.access.$class !== "subscription" ? <ToolbarMenuItem className="pl-2">
          <BuyButton className="min-w-10" theme={theme} />
        </ToolbarMenuItem> : null}
    </ul>;
}