"use client";

import clsx from "clsx";
import { usePathname, useSearchParams } from "next/navigation";
import { LinkButton } from "@/components/Button/components/LinkButton/LinkButton.component";
import { Translation } from "@/components/Translation/Translation.component";
type BuyButtonProps = {
  readonly className?: string;
  readonly theme?: "white" | "primary";
};
export function BuyButton({
  className,
  theme = "primary"
}: BuyButtonProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const shopParams = new URLSearchParams();
  shopParams.append("redirectUrl", `${pathname}?${searchParams.toString()}`);
  return <LinkButton className={clsx(className, "text-center text-sm font-theme-bold uppercase lg:min-h-0 lg:p-1.25")} href={`/shop?${shopParams.toString()}`} variant={theme === "primary" ? "primary" : "secondary-inverse"} data-sentry-element="LinkButton" data-sentry-component="BuyButton" data-sentry-source-file="BuyButton.component.tsx">
      <Translation da="Køb" de="Kaufen" en="Buy" no="Kjøp" sv="Köp" data-sentry-element="Translation" data-sentry-source-file="BuyButton.component.tsx" />
    </LinkButton>;
}